import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux/es/exports';

import { getCategoriesAsync } from '../../redux/categorySlice';
import NavSkeletons from '../Skeletons/NavSkeletons';
import { getSubscriberDetailsAsync } from '../../redux/subscriberSlice';

import { CiLogin } from 'react-icons/ci';
import { FaRegUser } from 'react-icons/fa6';
import { BsCart2 } from 'react-icons/bs';
import NavDrop from '../navDropdown';

const Navbar = ({ masterFilter, setmasterFiler }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.loading);
  const { user } = useSelector((state) => state.subscribers);
  const { cartItems } = useSelector((state) => state.cart);

  const [searchKey, setSearchKey] = useState('');
  useEffect(() => {
    if (user) {
      dispatch(getSubscriberDetailsAsync());
    }
  }, [user]);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, []);

  if (loading) return <NavSkeletons />;

  const filteredCat = categories.filter((cat) => cat.name === masterFilter);

  return (
    <>
      <section className='navMainWrapper'>
        <Container>
          <nav className='navWrapper'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {categories.map((category, index) => (
                <figure
                  key={index}
                  className='navWrapper-brand'
                  onClick={() => setmasterFiler(category.name)}
                  style={
                    category.name === masterFilter
                      ? { border: '2px solid #ad4290 ', borderRadius: '8px' }
                      : { border: '2px solid white ', borderRadius: '8px' }
                  }
                >
                  <Link to='/'>
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}${category.image}`}
                      alt={category.name}
                      style={
                        category.name === 'Loraaj'
                          ? {
                              width: '6.5rem',
                              height: '3rem',
                            }
                          : {}
                      }
                    />
                  </Link>
                </figure>
              ))}
            </div>
            {/* <CategoryDropdown categories={categories} /> */}

            <section className='navWrapper-search'>
              <input
                type='search'
                name='search'
                value={searchKey}
                onKeyUp={(e) =>
                  e.key === 'Enter' && navigate('/search', { state: searchKey })
                }
                id=''
                onChange={(e) => setSearchKey(e.target.value)}
                placeholder='search here ...'
              />
              <button
                className='bton bton--sm bton--primary  mobSearchBton'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/search', { state: searchKey });
                }}
              >
                <span className='mobsearch'>search</span>
              </button>
            </section>
            <section className='navWrapper-carts'>
              {user ? (
                <span className='navWrapper-carts-login'>
                  <Link to='/account'>
                    <span className=' me-1'>
                      <FaRegUser />{' '}
                    </span>
                    <span>My Account</span>
                  </Link>
                </span>
              ) : (
                <span className='navWrapper-carts-login'>
                  <Link to='/login'>
                    <span className=' me-1'>
                      <CiLogin />
                    </span>
                    <span>LOGIN</span>
                  </Link>
                </span>
              )}

              <span className='myCart'>
                <Link to='/cart'>
                  <BsCart2 />
                  &nbsp;
                  <div className='cartCount'>
                    {cartItems?.length > 0 ? cartItems.length : 0}
                  </div>
                </Link>
              </span>
            </section>
          </nav>

          <ul className='navCatList'>
            {filteredCat.length > 0 &&
              filteredCat[0].subCategory?.map((catItem, index) => (
                <NavDrop
                  catItem={catItem}
                  key={index}
                  slug={filteredCat[0].slug}
                  id={filteredCat[0]._id}
                />
              ))}

            <li>
              <Link to='/about'>About Us</Link>
            </li>
          </ul>
        </Container>
        {/* <section>
          <nav className='navbarBox'>
            <Container>
              <ul className='menu p-0 m-0'>
                {categories &&
                  categories.map((cat) => {
                    const data = {
                      cat_id: cat._id,
                      subData: null,
                      sub2Data: null,
                    };
                    return (
                      <>
                        <li className='menu--items'>
                          <Link
                            to={`/category/${cat.slug}`}
                            state={data}
                            className='navitems'
                          >
                            {cat.name}{' '}
                          </Link>
                          {cat.subCategory.length > 0 && (
                            <img src={Chevrone} alt='' />
                          )}
                          {cat.subCategory.length > 0 && (
                            <section className='sub--menu'>
                              {cat.subCategory &&
                                cat.subCategory.map((sub) => {
                                  const data = {
                                    cat_id: cat._id,
                                    subData: { _id: sub._id, name: sub.name },
                                    sub2Data: null,
                                  };
                                  return (
                                    <section className='subcatWrapper'>
                                      <div className='subcatWrapper--title'>
                                        <Link
                                          to={`/category/${cat.slug}`}
                                          state={data}
                                        >
                                          {sub.name}
                                        </Link>
                                      </div>
                                      <ul className='subcatWrapper--itemholder'>
                                        {sub.subCategory2 &&
                                          sub.subCategory2.map((sub2) => {
                                            const data = {
                                              cat_id: cat._id,
                                              subData: {
                                                _id: sub._id,
                                                name: sub.name,
                                              },
                                              sub2Data: {
                                                _id: sub2._id,
                                                name: sub2.name,
                                              },
                                            };
                                            return (
                                              <li className='subcatWrapper--itemholder__items'>
                                                <Link
                                                  to={`/category/${cat.slug}`}
                                                  state={data}
                                                >
                                                  {sub2.name}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </section>
                                  );
                                })}
                            </section>
                          )}
                        </li>
                      </>
                    );
                  })}
                <li className='menu--items'>
                  <Link className='navitems' to={`/cleaning`}>
                    Cleaning Facilities
                  </Link>
                </li>
              </ul>
            </Container>
          </nav>
        </section> */}
      </section>

      <section className='mobileWrapper'>
        <section className='mobileNavbar'>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-home'></i>
                <div className='mobileNavbar-items-name'>Home</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/mobcat'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-sliders'></i>
                <div className='mobileNavbar-items-name'>Categories</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/cart'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-shopping-cart
'
                ></i>
                <div className='mobileNavbar-items-name'>Cart</div>
                {cartItems?.length > 0 && (
                  <div className='cartMobCount'>{cartItems.length}</div>
                )}
              </section>{' '}
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/more'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-gear
'
                ></i>
                <div className='mobileNavbar-items-name'>More</div>
              </section>{' '}
            </NavLink>
          </section>
        </section>
      </section>
    </>
  );
};

export default Navbar;
