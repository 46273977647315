import React, { useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi2';
import { Link } from 'react-router-dom';

function NavDrop({ catItem, slug, id }) {
  const [toggleNav, setToggleNav] = useState(false);

  const data = {
    cat_id: id,
    subData: { _id: catItem._id, name: catItem.name },
    sub2Data: null,
  };

  return (
    <li className='navCatList-item'>
      <div
        className='navOptionBox'
        onMouseOver={() => setToggleNav(true)}
        onMouseLeave={() => setToggleNav(false)}
      >
        <Link to={`/category/${slug}`} state={data}>
          <span>
            {catItem.name}{' '}
            {catItem.subCategory2.length > 0 && <HiOutlineChevronDown />}
          </span>
        </Link>
        <ul
          className='navOptionBox-listItems'
          onMouseLeave={() => setToggleNav(false)}
        >
          {toggleNav &&
            catItem.subCategory2.map((catItem2, index) => {
              const data = {
                cat_id: id,
                subData: {
                  _id: catItem._id,
                  name: catItem.name,
                },
                sub2Data: {
                  _id: catItem2._id,
                  name: catItem2.name,
                },
              };

              return (
                <Link to={`/category/${slug}`} state={data}>
                  <li key={index} className='navOptionBox-listItems-item'>
                    {catItem2.name}
                  </li>
                </Link>
              );
            })}
        </ul>
      </div>
    </li>
  );
}

export default NavDrop;
