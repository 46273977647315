import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import LoaderComponent from '../LoaderComponent';
import ProductCard from '../ProductCard';
import ProductSkeletons from '../Skeletons/ProductsSkeletons';

const BestSelling = ({ masterFilter }) => {
  const [featured, setFeatured] = useState([]);
  const [floading, setFLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const fProducts = await axios.get('/api/products/featured');
      setFeatured(fProducts.data);
      setFLoading(false);
    };
    if (floading) {
      fetch();
    }
  }, [floading]);

  if (floading) return <ProductSkeletons />;

  const filteredFeatured = featured.filter(
    (newItem) => newItem.category.name === masterFilter
  );

  return (
    <>
      <Container>
        <section className='layoutTitle'>
          <h1>Best Selling Products</h1>
          <p>Explore Best sellling products in our store</p>
        </section>
        <section>
          <Row>
            {filteredFeatured.length > 0 ? (
              filteredFeatured.map((fp) => {
                return <ProductCard key={fp._id} fProduct={fp} column={3} />;
              })
            ) : (
              <div
                style={{ margin: '3rem 0', textAlign: 'center', color: 'grey' }}
              >
                No best selling products found
              </div>
            )}
          </Row>
        </section>
      </Container>
    </>
  );
};

export default BestSelling;
