import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Container className='mt-3'>
        <h1>Terms & Conditions</h1>
        <p>
          <br /> <br />
          Purchase Conditions The following are the standard sales conditions
          for consumer purchases of goods made over the internet. The purchase
          is governed by these conditions, and consumer purchases are mainly
          regulated by several acts such as the
          <br /> <br /> • Contracts Act, <br /> <br />• The Consumer Purchases
          Act,
          <br /> <br /> • The Marketing Act,
          <br /> <br /> • The Right of Cancellation Act, and <br /> <br />• The
          E-commerce Act. <br /> <br />
          These laws provide the consumer with inalienable rights, and the terms
          of this agreement should not be viewed as limiting these statutory
          rights. Instead, they outline the most significant rights and
          obligations for the trade. The ordering solution's information, the
          sales conditions, and any individually agreed-upon terms are all
          included in the agreement. In the event of a discrepancy between such
          information, the individually agreed-upon terms shall control,
          provided that such terms do not conflict with applicable law. The
          agreement will be supplemented by any applicable legislative clauses
          that govern the exchange of commodities between businesses and
          consumers.
          <br /> <br />
          Parties: The parties to the agreement are the buyer, who is the
          customer placing the order, and the seller, loraaj, with addresses at
          Beverveien 29 0596 OSLO, Norway and organization number 826500212.
          <br /> <br />
          VAT is included in every price displayed in the online store. The
          buyer must pay the full amount, which includes all taxes and
          additional expenses, as mentioned in the pricing for the goods and
          services. The buyer is not liable for any additional expenses that the
          seller did not disclose to them before the sale. The seller maintains
          the right to adjust prices, deal with colour discrepancies and
          sold-out items, and fix typographical errors. When the customer sends
          the order to the seller, the agreement is legally binding for both
          parties. If there is an error in the buyer's order, the seller's
          offer, or the ordering system in the online store, and the other party
          knew or should have known that there was an error, the agreement is
          not binding.
          <br /> <br />
          From the moment the goods are sent to the customer, the seller has the
          right to request payment. If the buyer pays using a credit or debit
          card, the vendor will put the whole purchase price on the card when
          the order is placed. On the day the item is sent, the card is charged.
          <br /> <br />
          When the buyer or their agent takes possession of the item, the
          delivery has occurred. The seller must deliver the items to the buyer
          without undue delay and no later than 30 days following the customer's
          order if there is no delivery deadline indicated in the order
          solution. Unless the parties otherwise agree, the products must be
          delivered to the customer. Reservations and orders are not delivered
          to Svalbard. When the buyer or their representative receives the
          items, the risk of the goods shifts to them. The buyer can cancel the
          purchase within 14 days of receiving the item by notifying the seller
          of the use of the right of withdrawal. The buyer has the burden of
          proof that the right of withdrawal has been exercised, and the
          notification should be made in writing (right of the withdrawal form,
          e-mail, or letter). The goods must be returned to the seller without
          undue delay and no later than 14 days from the notification of the use
          of the right of withdrawal. The buyer is responsible for the direct
          costs of returning the item unless otherwise agreed upon or the seller
          has failed to state that the buyer must cover the return costs. The
          seller cannot charge a fee for the buyer's use of the right of
          withdrawal. The buyer may examine or test the goods reasonably and
          necessarily to determine their nature, properties, and function,
          without losing the right of withdrawal. If the examination or testing
          goes beyond what is reasonable and necessary, the buyer may be held
          liable for any reduced value of the goods. Without undue delay and
          within 14 days of obtaining notice that the buyer intends to exercise
          their right to cancel, the seller is required to reimburse the
          customer for the purchase amount. The seller has the right to withhold
          payment until either the buyer provides proof that the items have been
          returned or the seller receives the goods from the buyer.
          <br /> <br />
          The buyer has rights under Chapter 5 of the Consumer Buying Act if the
          seller fails to deliver the products or delivers them after the
          scheduled time and it was not the customer's fault. These options
          include delaying payment, requesting delivery of the items,
          terminating the contract, and requesting financial compensation from
          the supplier. To have written proof of their claim, the buyer should
          submit any claims for default powers in writing, such as by email. The
          buyer has the right to stand by the deal and ask the seller to carry
          out their end of the bargain. Nevertheless, the buyer cannot insist on
          fulfilment if there is an insurmountable hurdle that the seller is
          unable to overcome, or if the expense or inconvenience to the seller
          to fulfil is disproportionately great compared to the buyer's interest
          in the fulfilment. Nonetheless, the buyer may still ask for fulfilment
          if the obstruction is removed within a reasonable amount of time. If
          the buyer unnecessarily postpones making the claim, they lose their
          right to demand fulfilment.
          <br /> <br />
          The buyer should ask the vendor to deliver the items within a
          reasonable extension of time if the seller doesn't deliver at the
          scheduled time. The buyer may cancel the order if the supplier does
          not deliver the products by the extended deadline. Nonetheless, the
          buyer has the right to cancel the order right away if the vendor
          declines to deliver the item. The same holds if delivery at the
          appointed time was necessary for the agreement to be finalized or if
          the buyer has already informed the seller that the delivery time is
          important. Buyer must submit a claim for cancellation within a
          reasonable amount of time if the items are delivered after the
          buyer-imposed extension date or after the delivery window required by
          the agreement. Unless the seller can demonstrate that the delay was
          brought on by an unforeseen barrier beyond their control that they
          were unable to avoid or alleviate, the buyer has the right to seek
          compensation for small losses brought on by the delay.
          <br /> <br />
          Defects in goods: If a product has a defect, the buyer should inform
          the seller within a reasonable time after discovering the defect or
          when it should have been discovered. If the buyer informs the seller
          within two months of discovering the defect, it is considered timely.
          Complaints can be made within two years of taking over the item, or
          five years if the product is intended to last significantly longer. If
          the defect is not due to the buyer or circumstances on their side, the
          buyer can withhold the purchase price, demand rectification or
          redelivery, ask for a price reduction, demand the contract be
          terminated, and/or ask for compensation from the seller, according to
          Chapter 6 of the Consumer Purchase Act. Complaints should be made in
          writing. The buyer can choose between rectification and redelivery of
          equivalent items. However, if it is impossible or too costly for the
          seller, they can object. The seller must make the correction or
          redelivery within a reasonable time and cannot make more than two
          remedial attempts for the same defect. If the item is not corrected or
          redelivered, the buyer can demand a suitable price discount. The price
          reduction should correspond to the ratio between the reduced and
          agreed price and the item's value in a defective and contractual
          condition. In some cases, the price discount can be equal to the
          defect's significance to the buyer. If the defect is not insignificant
          and the goods have not been corrected or redelivered, the buyer can
          cancel the purchase.
          <br /> <br />
          If the buyer fails to fulfil their obligations under the agreement or
          the law, such as not paying for the goods, the seller has the right to
          withhold the goods, demand fulfilment of the agreement, terminate the
          agreement, and seek compensation from the buyer following the Consumer
          Purchase Act, chapter 9. Additionally, the seller may demand interest
          for late payment, debt collection fees, and a reasonable fee for
          uncollected goods, depending on the circumstances. The seller can
          demand that the buyer pays the purchase price and fulfil their
          obligations under the agreement. However, the seller cannot demand
          fulfilment if they wait an unreasonably long time to make the claim,
          or if the goods have already been delivered. If the buyer has a
          significant payment default or other significant default, the seller
          has the right to terminate the agreement. However, the seller cannot
          withdraw if the entire purchase price has been paid. If the seller
          sets a reasonable deadline for fulfilment and the buyer fails to pay
          within this deadline, the seller can cancel the purchase. Interest for
          late payment/debt collection fee If the buyer fails to pay the
          purchase price, the seller can claim interest on the purchase price
          under the Late Interest Act. If the buyer still doesn't pay after
          being notified, they may be held liable for fees under the Debt
          Collection Act. Fee for uncollected, non-prepaid goods If the buyer
          fails to collect unpaid goods, the seller can charge a fee that covers
          their actual outlay for delivering the goods to the buyer. However,
          this fee cannot be charged to buyers who are under the age of 18.
          <br /> <br />
          Guarantee: When a seller or manufacturer provides a warranty, it adds
          to the buyer's existing rights under non-derogable legislation.
          Therefore, a warranty does not limit the buyer's right to file a
          complaint or claim for delay or defects, as explained in sections 9
          and 10. Data:
          <br /> <br />
          Personal Information By using this service, you agree to the
          processing of your personal information as outlined in our privacy
          policy.
          <br /> <br />
          Cookies: Information about Cookies By purchasing goods through Loraaj,
          you agree to the use of cookies. Cookies, also known
          as information capsules, are a standard technology that most websites
          use today. These are small text files or data stored in your browser.
          <br /> <br />
          Conflict resolution: Conflict Resolution Complaints should be
          addressed to the seller within a reasonable timeframe, as described in
          sections 9 and 10. The parties should attempt to resolve any disputes
          in a friendly manner. If this is not possible, the buyer may contact
          the Consumer Council for mediation.
        </p>
      </Container>
    </div>
  );
};

export default Terms;
